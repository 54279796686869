import { FEMALE_CHEF_ICON, SOCIAL_IMPACT_ICON, SUSTAINABLE_RESTAURANT_ICON, HEALTH_FOCUSED_ICON } from "../../uber-assets/inline-svgs";

const FEMALE_CHEF_OF_THE_YEAR = {
    id: 'female_chef',
    label: 'Female Chef of the Year',
    icon: 'icon_female@2x.png',
    svgIcon: FEMALE_CHEF_ICON,
    match: ['femalechef', 'female', 'f'],
}
const SUSTAINABLE_RESTAURANT_OF_THE_YEAR = {
    id: 'sustainable_restaurant',
    label: 'Sustainable Restaurant of the Year',
    icon: 'icon_sustainability@2x.png',
    svgIcon: SUSTAINABLE_RESTAURANT_ICON,
    match: ['sustainablerestaurant', 'sustainable', 's'],
}
const SOCIAL_IMPACT_RESTAURANT_OF_THE_YEAR = {
    id: 'social_impact',
    label: 'Social Impact Restaurant of the Year',
    icon: 'icon_social@2x.png',
    svgIcon: SOCIAL_IMPACT_ICON,
    match: ['socialimpact', 'social', 'si', 'c', 'community'],
}
const HEALTH_FOCUSED_RESTAURANT_OF_THE_YEAR = {
    id: 'health_focused',
    label: 'Health Focused Restaurant of the Year',
    icon: 'icon_healthfocused@2x.png',
    svgIcon: HEALTH_FOCUSED_ICON,
    match: ['healthfocused', 'health', 'h'],
}

export const TRAILBLAZER_CATEGORIES = {
    female_chef: FEMALE_CHEF_OF_THE_YEAR,
    sustainable_restaurant: SUSTAINABLE_RESTAURANT_OF_THE_YEAR,
    health_focused: HEALTH_FOCUSED_RESTAURANT_OF_THE_YEAR,
    social_impact: SOCIAL_IMPACT_RESTAURANT_OF_THE_YEAR,
}

export const TRAILBLAZER_BUTTONS = [
    {
        label: 'Women in Foor Award',
        category: FEMALE_CHEF_OF_THE_YEAR,
    },
    {
        label: 'Climate Impact Award',
        category: SUSTAINABLE_RESTAURANT_OF_THE_YEAR,
    },
    {
        label: 'Healthy Eats Award',
        category: HEALTH_FOCUSED_RESTAURANT_OF_THE_YEAR,
    },
    {
        label: 'Social Impact Award',
        category: SOCIAL_IMPACT_RESTAURANT_OF_THE_YEAR,
    },
]

export function findCategoryData(search) {
    search = search.toLowerCase();
    // replace - with '' in the search string
    search = search.replace(/-/g, '');
    if (TRAILBLAZER_CATEGORIES[search]) {
        return TRAILBLAZER_CATEGORIES[search];
    }
    for (const key in TRAILBLAZER_CATEGORIES) {
        const k = key.replace(/_/g, '');
        if (k === search) {
            return TRAILBLAZER_CATEGORIES[key];
        }
        const category = TRAILBLAZER_CATEGORIES[key];
        if (category.match === search) {
            return category;
        }
        if (Array.isArray(category.match) && category.match.includes(search)) {
            return category;
        }
    }
    return null;
}
