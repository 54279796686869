export const PHASES = {
    LAUNCHING_SOON: 'LAUNCHING_SOON',
    VOTE: 'VOTE',
    VOTE_ENDED: 'VOTE_ENDED',
    FINALISTS: 'FINALISTS',
    WINNERS: 'WINNERS',
}

// NOTE: THESE ARE END TIMES, NOT START TIMES!!!
export const PHASE_END_TIMES = {
    // Testing times
    // LAUNCHING_SOON: 1725364500,         // Tuesday, September 3, 2024 12:55:00 PM GMT+01:00 (BST)
    // Tuesday, September 3, 2024 12:55:00 PM GMT+01:00 (BST)

    // Real times
    LAUNCHING_SOON: 1725404460,         // 1725404460 = 4th September 2024 00:01:00 BST
    // Voting starts 4th September 2024 00:01:00 UTC

    // Settings voting ends and finalists to a long off date until we get the proper dates
    VOTE: 1728082860,                   // 1728082860 = 5th Oct 2024 00:01:00am                   // 1727947800 = 3rd Oct 2024 10:30am BST (for testing)                   // 1893456000 = 1st January 2030 00:00:00 UTC
    VOTE_ENDED: 1893456000,             // 1893456000 = 1st January 2030 00:00:00 UTC
    FINALISTS: 1893456000,              // 1893456000 = 1st January 2030 00:00:00 UTC
}

export const PHASES_ORDER = [
    PHASES.LAUNCHING_SOON,
    PHASES.VOTE,
    PHASES.VOTE_ENDED,
    PHASES.FINALISTS,
    PHASES.WINNERS,
]

export function getPhase() {
    if (ALLOW_PHASE_OVERRIDE_IN_URL && new URLSearchParams(window.location.search).has('phase')) {
        const phase = new URLSearchParams(window.location.search).get('phase');
        if (Object.values(PHASES).includes(phase)) {
            return {
                phase: phase,
                timeLeft: 0,
            };
        }
    }
    if (FORCE_PHASE) {
        return {
            phase: FORCE_PHASE,
            timeLeft: 0,
        };
    }

    const now = Math.floor(Date.now() / 1000);
    for (let phase of PHASES_ORDER) {
        if (now < PHASE_END_TIMES[phase]) {
            return {
                phase: phase,
                timeLeft: PHASE_END_TIMES[phase] ? PHASE_END_TIMES[phase] - now : 0,
            };
        }
    }
    return PHASES.WINNERS;
}

// Set to null to go with the timed phases
const ALLOW_PHASE_OVERRIDE_IN_URL = false;
const FORCE_PHASE = null; // PHASES.VOTE_ENDED;
