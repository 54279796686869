import { useEffect, useState } from 'react';
import TrailblazersIcon from '../../uber-assets/small-trailblazer-logo.svg';
import { TRAILBLAZER_BUTTONS, TRAILBLAZER_CATEGORIES } from '../model/trailblazer-categories';
import { GetNomineesByCategory, GetWinners } from '../model/trailblazer-nominees';
import { TrailblazerCard } from './trailblazer-card/trailblazer-card';
import nl2br from 'react-nl2br';
import { PHASES } from '../model/phases';
import { WinnerCard } from './winner-card/winner-card';

import TrailBlazerBadgeAnim from '../../uber-assets/tb-badge.json';
import Lottie from 'react-lottie';

export function TrailblazersSection({showTrailblazerInfo, instagramFunction, orderFunction, phase, infoFunction, initialCategory, cardTransitionInFunction, cardTransitionOutFunction}) {
    const [activeCategory, setActiveCategory] = useState(initialCategory || TRAILBLAZER_CATEGORIES.female_chef.id);
    const [nextCategory, setNextCategory] = useState(null);
    const [switchingCategory, setSwitchingCategory] = useState(false);
    const switchCategory = (categoryId) => {
        if (!switchingCategory) {
            const switchCategoryNow = () => {
                setActiveCategory(categoryId);
                setSwitchingCategory(false);
                setTimeout(() => {
                    cardTransitionInFunction('trailblazer-card');
                }, 100);
            }

            setSwitchingCategory(true);
            setNextCategory(categoryId);
            cardTransitionOutFunction('trailblazer-card', switchCategoryNow)
        }
    }

    const showFinalists = phase === PHASES.FINALISTS || phase === PHASES.WINNERS;
    const showWinner = phase === PHASES.WINNERS;

    const [categoryNominees, setCategoryNominees] = useState([]);
    useEffect(() => {
        setCategoryNominees(GetNomineesByCategory(activeCategory));
    }, [activeCategory]);

    return (
        <section className='trailblazers-section' id="trailblazers">
            <div className="section-container">
                <div className='content'>
                    <div className="column roty-section-heading">
                        <div className="section-image-heading-container">
                            {/* <img className='section-heading-image' src={TrailblazersIcon} alt="Trailblazers Awards" /> */}
                            <div className='section-heading-image'>
                                <Lottie
                                    options={{
                                        loop: true,
                                        autoplay: true,
                                        animationData: TrailBlazerBadgeAnim,
                                        rendererSettings: {
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }
                                    }}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        zIndex: 1,
                                    }}
                                />
                            </div>
                            <h1>Trailblazers Awards</h1>
                        </div>
                        {(phase === PHASES.VOTE) &&
                            <>
                                <p>As part of the Restaurant of the Year 2024 awards, Uber Eats would like to celebrate our restaurant partners who make a positive difference in their communities and the world, by recognising them formally at the Restaurant of the Year awards ceremony in London in November 2024. We will celebrate one winner for each Trailblazers category:</p>
                                <ul>
                                    <li className="left-align">Women in Food Award</li>
                                    <li className="left-align">Climate Impact Award</li>
                                    <li className="left-align">Healthy Eats Award</li>
                                    <li className="left-align">Social Impact Award</li>
                                </ul>
                                <p className={`${showWinner ? 'extra-bottom-pad' : ''}`}>You have until the 4th of October to nominate your restaurant by filling and submitting the Trailblazers nomination form below.</p>
                                <button className="trailblazer-nominate-button black" onClick={() => window.open("https://docs.google.com/forms/d/1xmCRoxNzNSSdEt5X_osmu8FoyspgvnW55oh-VXJgkeU/viewform?edit_requested=true", "_blank")}>Nominate Now</button>
                            </>
                        }
                        {(phase === PHASES.VOTE_ENDED) &&
                            <>
                                <p>As part of the Restaurant of the Year 2024 awards, Uber Eats would like to celebrate our restaurant partners who make a positive difference in their communities and the world, by recognising them formally at the Restaurant of the Year awards ceremony in London in November 2024. We will celebrate one winner for each Trailblazers category:</p>
                                <ul>
                                    <li className="left-align">Women in Food Award</li>
                                    <li className="left-align">Climate Impact Award</li>
                                    <li className="left-align">Healthy Eats Award</li>
                                    <li className="left-align">Social Impact Award</li>
                                </ul>
                                <p className={`${showWinner ? 'extra-bottom-pad' : ''}`}>We will announce the finalists on the 16th of October!</p>
                            </>
                        }
                    </div>
                </div>

                        {showWinner &&
                            <>
                                <div className="cards-container extra-bottom-pad">
                                    {GetWinners().map((winner, index) => {
                                        const categoryData = TRAILBLAZER_CATEGORIES[winner.category];
                                        return (
                                            <WinnerCard key={index} icon={categoryData.svgIcon} category={categoryData.label} winner={winner} infoFunction={infoFunction} instagramFunction={instagramFunction} orderFunction={orderFunction} isNarrow={true} />
                                        )
                                    })}
                                    
                                </div>
                                <h3 className='extra-bottom-pad'>Congratulations to all of our Uber Eats Trailblazers Awards 2024 finalists...</h3>
                            </>
                        }

                {/* <div className='content'>
                    <div className="column roty-section-heading">
                        <div className='cat-buttons-container'>
                            {TRAILBLAZER_BUTTONS.map((category, index) => {
                                return (
                                    <button className={`cat-button tbcat${((category.category.id === activeCategory && !switchingCategory) || (category.category.id === nextCategory && switchCategory)) ? ' active' : ''}`} key={index} onClick={(e) => switchCategory(category.category.id)} disabled={category.category.id === activeCategory}>
                                        <img src={'./img/' + category.category.icon} alt={category.label} />
                                        <div className='shrink'>{nl2br(category.label)}</div>
                                    </button>
                                )
                            })}
                        </div>
                        <select className='cat-drop-down trailblazers' onChange={(e) => switchCategory(e.target.value)} value={activeCategory}>
                            {TRAILBLAZER_BUTTONS.map((category, index) => {
                                return (
                                    <option key={index} value={category.category.id}>{category.label}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="cards-container">
                    {categoryNominees.map((nominee, index) => {
                        return (
                            <TrailblazerCard key={index} trailblazer={nominee} infoFunction={showTrailblazerInfo} instagramFunction={instagramFunction} orderFunction={orderFunction} finalist={showFinalists} />
                        )
                    })}
                </div> */}
            </div>
        </section>
    );
}
