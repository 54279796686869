import "../uber.css";
import { Nav } from "../components/nav";
import { AboutHeader } from "../components/about-header";
import { ROTYSection } from "../components/roty-section";
import { TrailblazersSection } from "../components/trailblazers-section";
import { PreviousWinnersSection } from "../components/previous-winners-section";
import { FaqSection } from "../components/faq-section";
import { Footer } from "../components/footer";

import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { InfoPopout } from "../components/info-popout/info-popout";
import { useEffect, useRef, useState } from "react";

import nl2br from "react-nl2br";
import { INSTA_ICON, ROTY_WINNER_ICON, SHARE_ICON, FEMALE_CHEF_ICON, SOCIAL_IMPACT_ICON, SUSTAINABLE_RESTAURANT_ICON, HEALTH_FOCUSED_ICON } from "../../uber-assets/inline-svgs";
import { AREAS_BY_ID, findRegionData } from "../model/areas";

import LocationIcon from '../../uber-assets/location-icon.svg';
import { PrevWinnerCard } from "../components/prev-winner-card/prev-winner-card";
import { PREV_WINNERS } from "../model/prev-winners";
import Carousel from "react-multi-carousel";
import { CustomDot } from "../components/carousel/custom-dot";
import API_CONTROLLER, { RECAPTCHA_STATE } from "../controller/ApiController";
import ReCAPTCHA from "react-google-recaptcha";
import { v4 as uuidv4 } from 'uuid';
import { PHASES, getPhase } from "../model/phases";

import { fb } from "../controller/ShareController";
import { FB_HASHTAGS, SHARE_MESSAGE, SHARE_RAW_HASHTAGS } from "../model/sharing";
import { findRestaurantData, RESTAURANT_SHORTLIST } from "../model/restaurants";
import { findCategoryData } from "../model/trailblazer-categories";
import { findNomineeData } from "../model/trailblazer-nominees";
import { ComingSoon } from "../components/coming-soon";

export function Main() {
    gsap.registerPlugin(ScrollToPlugin);

    const SECTION_ANIM_IN_OFFSET = 80;
    const INITIAL_LOAD_ANIM_IN_Y_OFFSET = 40;
    const sectionAnimatedIn = useRef([]);
    const scrollAnimInHandler = (e, initialLoad = false) => {
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const sections = document.querySelectorAll('section');
        sections.forEach((section) => {
            if (sectionAnimatedIn.current.indexOf(section) === -1) {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                if (scrollY + windowHeight - (initialLoad ? INITIAL_LOAD_ANIM_IN_Y_OFFSET : SECTION_ANIM_IN_OFFSET) > sectionTop && scrollY < sectionTop + sectionHeight) {
                    if (section.classList.contains('section-anim-in')) {
                        return;
                    }
                    section.classList.add('section-anim-in');
                    sectionAnimatedIn.current.push(section);
                }
            }
        });
        const cards = document.querySelectorAll('.card-anim-start');
        let cardAnimDelay = 0;
        cards.forEach((card) => {
            if (scrollY + windowHeight - SECTION_ANIM_IN_OFFSET > card.offsetTop) {
                if (card.classList.contains('card-anim-in') || card.classList.contains('card-anim-out')) {
                    return;
                }
                card.classList.add('card-anim-in');
                card.style.animationDelay = (cardAnimDelay * 0.1) + 's';
                cardAnimDelay++;
            }
        });
    }
    useEffect(() => {
        setTimeout(() => {
            window.addEventListener('scroll', scrollAnimInHandler);
            scrollAnimInHandler(null, true);

            /* console.log('----- RESTAURANT LIST FOR PHP API -----');
            let rList = (`$r_names=[\n  '',\n`);
            for (let i = 0; i < RESTAURANT_SHORTLIST.length; i++) {
                rList += (`  '${RESTAURANT_SHORTLIST[i].name}',\n`);
            }
            rList += ('];\n');
            console.log(rList);
            console.log('----- END RESTAURANT LIST -----'); */
        }, 800);
        return () => {
            window.removeEventListener('scroll', scrollAnimInHandler);
        }
    }, []);

    const cardTransitionOutFunction = (cardClassToFind, tranitionOverCallback) => {
        const cards = document.querySelectorAll('.' + cardClassToFind);
        let cardAnimDelay = 0;
        cards.forEach((card) => {
            if (card.classList.contains('card-anim-out')) {
                return;
            }
            card.classList.remove('card-anim-in');
            card.classList.remove('card-anim-start');
            card.classList.add('card-anim-out');
            card.style.animationDelay = (cardAnimDelay * 0.1) + 's';
            cardAnimDelay++;
            if (cardAnimDelay > 6) {
                cardAnimDelay = 6;
            }
        });
        setTimeout(() => {
            if (tranitionOverCallback) {
                tranitionOverCallback();
            }
        }, cardAnimDelay * 100 + 200);
    }

    const cardTransitionInFunction = (cardClassToFind, tranitionOverCallback) => {
        const cards = document.querySelectorAll('.' + cardClassToFind);
        let cardAnimDelay = 0;
        cards.forEach((card) => {
            if (card.classList.contains('card-anim-in')) {
                return;
            }
            card.classList.remove('card-anim-out');
            card.classList.add('card-anim-start');
            card.classList.add('card-anim-in');
            card.style.animationDelay = (cardAnimDelay * 0.2) + 's';
            cardAnimDelay++;
            if (cardAnimDelay > 9) {
                cardAnimDelay = 9;
            }
        });
        setTimeout(() => {
            if (tranitionOverCallback) {
                tranitionOverCallback();
            }
        }, cardAnimDelay * 200 + 700);
    }

    const scrollToSection = (sectionId, mobile = false) => {
        // document.getElementById(sectionId).scrollIntoView({behavior: 'smooth'});
        gsap.to(window, { duration: 0.5, scrollTo: { y: `#${sectionId}`, offsetY: sectionId === 'top' ? 170 : mobile ? 40 : 80 } });
    }

    const openLink = (url) => {
        window.open(url, '_blank');
    }

    const goToInstagram = (winner) => {
        const insta = winner.instagram;
        if (insta.indexOf('instagram.com') === -1) {
            openLink('https://www.instagram.com/' + insta);
        } else {
            openLink(winner.instagram);
        }
    }

    const goToOrder = (winner) => {
        openLink(winner.orderlink);
    }

    const share = (restaurantData, restaurantId = -1) => {
        let shareLink = 'https://www.ubereatsawards.com/';
        if (restaurantId !== -1) {
            shareLink += '?r=' + restaurantId;
        }
        const customMessage = restaurantId === -1 ? ' ' : ' I voted for ' + restaurantData.name + ', who will you vote for? ';
        const shareTag = restaurantId === -1 ? FB_HASHTAGS : FB_HASHTAGS + ' #VoteNow #' + restaurantData.name.replace(/ /g, '');
        fb.share_ui(SHARE_MESSAGE + customMessage + SHARE_RAW_HASHTAGS, shareTag, shareLink);
    }

    const [showingPreviousWinners, setShowingPreviousWinners] = useState(false);
    const previousWinnersClosedCallback = () => {
        setShowingPreviousWinners(false);
    }
    const showPreviousWinners = () => {
        setShowingPreviousWinners(true);
    }

    const [showingRestaurantInfo, setShowingRestaurantInfo] = useState(false);
    const [restaurantInfoData, setRestaurantInfoData] = useState(null);
    const restaurantInfoClosedCallback = () => {
        setShowingRestaurantInfo(false);
    }
    const showRestaurantInfo = (restaurantInfoData) => {
        setRestaurantInfoData(restaurantInfoData);
        setShowingRestaurantInfo(true);
    }

    const [showingTrailblazerInfo, setShowingTrailblazerInfo] = useState(false);
    const [trailblazerInfoData, setTrailblazerInfoData] = useState(null);
    const trailblazerInfoClosedCallback = () => {
        setShowingTrailblazerInfo(false);
    }
    const showTrailblazerInfo = (trailblazerInfoData) => {
        setTrailblazerInfoData(trailblazerInfoData);
        setShowingTrailblazerInfo(true);
    }

    // Voting
    const [recaptchaState, setRecaptchaState] = useState(RECAPTCHA_STATE.LOADING),
        [recaptchaToken, setRecaptchaToken] = useState(null),
        [showRecaptcha, setShowRecaptcha] = useState(false),
        [lastVote, setLastVote] = useState(null),
        [lastRegion, setLastRegion] = useState(null),
        [guid, setGuid] = useState(null),
        [currentVotes, setCurrentVotes] = useState([]),
        [currentPhase, setCurrentPhase] = useState(null);

    const refreshPhase = () => {
        const phaseData = getPhase();
        setCurrentPhase(phaseData.phase);
        if (phaseData.timeLeft > 0) {
            setTimeout(refreshPhase, phaseData.timeLeft * 1000);
        }
    }

    const storageAvailable = (type = "localStorage") => {
        let storage;
        try {
            storage = window[type];
            const x = "__storage_test__";
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === "QuotaExceededError" ||
                    // Firefox
                    e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage &&
                storage.length !== 0
            );
        }
    }

    const registerVote = (vote, region, skipRecaptcha = false) => {
        // disable voting for now
        // return;

        let voteIndex = vote;
        // Allow us to submit the vote by index or by restaurant data object
        if (typeof voteIndex !== 'number') {
            if (voteIndex.id) {
                voteIndex = voteIndex.id;
            } else {
                console.error('Invalid vote: ', vote);
                return;
            }
        }

        if (currentVotes.indexOf(voteIndex) === -1) {
            if (!skipRecaptcha && recaptchaState !== RECAPTCHA_STATE.VERIFIED) {
                setLastVote(voteIndex);
                setLastRegion(region);
                setShowRecaptcha(true);
            } else {
                let votes = currentVotes;
                votes.push(voteIndex);
                setCurrentVotes(votes);
                setLastVote(null);
                setLastRegion(null);
                API_CONTROLLER.PostVote(voteIndex, guid, recaptchaToken, region);
                // document.cookie = 'votes = ' + votes.join(',');
                if (storageAvailable()) {
                    localStorage.setItem('votes', votes.join(','));
                }

                setRecaptchaState(RECAPTCHA_STATE.LOADING);
                setRecaptchaToken(null);
                setShowRecaptcha(false);
            }
        }
    }

    const captchaOnChange = (value) => {
        // console.log("Captcha value:", value);
        setRecaptchaState(value === null ? RECAPTCHA_STATE.READY : RECAPTCHA_STATE.VERIFIED);
        setRecaptchaToken(value);
        if (value !== null) {
            setShowRecaptcha(false);
            if (lastVote !== null && lastRegion !== null) {
                registerVote(lastVote, lastRegion, true);
            }
        }
    }

    useEffect(() => {
        let uuid = uuidv4();
        let guid = storageAvailable() ? localStorage.getItem('guid') : null;
        if (guid) {
            uuid = guid;
        }
        if (storageAvailable()) {
            localStorage.setItem('guid', uuid);
        }
        setGuid(uuid);

        let storedVotes = storageAvailable() ? localStorage.getItem('votes') : null;
        if (storedVotes) {
            // console.log('Got stored votes: ', storedVotes);
            const votesSplit = storedVotes.split(',');
            for (let i = 0; i < votesSplit.length; i++) {
                votesSplit[i] = Number(votesSplit[i]);
            }
            setCurrentVotes(votesSplit);
        }

        refreshPhase();

        // Check for r and tnom in the URL
        // This runs in useEffect because otherwise we would keep re-rendering the page and opening the side panel
        const urlParams = new URLSearchParams(window.location.search);

        // A few different ways to pass in the restaurant or trailblazer data
        // Which one we use depends if we favour readability or shortness, I guess
        // But leaving them in means people can use any of them, guess their url etc
        const rnom = urlParams.get('rnom');
        const r = urlParams.get('r');
        const restaurant = urlParams.get('restaurant');
        const tnom = urlParams.get('tnom');
        const t = urlParams.get('t');
        const trailblazer = urlParams.get('trailblazer');

        if (r) {
            const restaurantData = findRestaurantData(r);
            if (restaurantData) {
                showRestaurantInfo(restaurantData);
            }
        } else if (restaurant) {
            const restaurantData = findRestaurantData(restaurant);
            if (restaurantData) {
                showRestaurantInfo(restaurantData);
            }
        } else if (rnom) {
            const restaurantData = findNomineeData(rnom);
            if (restaurantData) {
                showRestaurantInfo(restaurantData);
            }
        } else if (tnom) {
            const trailblazerData = findNomineeData(tnom);
            if (trailblazerData) {
                showTrailblazerInfo(trailblazerData);
            }
        } else if (t) {
            const trailblazerData = findNomineeData(t);
            if (trailblazerData) {
                showTrailblazerInfo(trailblazerData);
            }
        } else if (trailblazer) {
            const trailblazerData = findCategoryData(trailblazer);
            if (trailblazerData) {
                showTrailblazerInfo(trailblazerData);
            }
        }

    }, []);

    // Check for initial region and tcat in the URL
    // This needs to be done here so it is immediate, rather than waiting for the useEffect to run
    // It works because the components use useState to set the initial values
    const urlParams = new URLSearchParams(window.location.search);

    // A couple of options for this as well...
    // Which one we use depends if we favour readability or shortness, I guess
    // But leaving them in means people can use any of them, guess their url etc
    const region = urlParams.get('region');
    const area = urlParams.get('area');
    const reg = urlParams.get('reg');
    const tcat = urlParams.get('tcat');
    const category = urlParams.get('category');
    const cat = urlParams.get('cat');

    let initialRegion = null;
    let initialTrailblazerCat = null;

    if (region) {
        const regionData = findRegionData(region);
        if (regionData) {
            initialRegion = regionData.id;
        }
    } else if (area) {
        const regionData = findRegionData(area);
        if (regionData) {
            initialRegion = regionData.id;
        }
    } else if (reg) {
        const regionData = findRegionData(reg);
        if (regionData) {
            initialRegion = regionData.id;
        }
    }
    if (tcat) {
        const trailblazerData = findCategoryData(tcat);
        if (trailblazerData) {
            initialTrailblazerCat = trailblazerData.id;
        }
    } else if (category) {
        const trailblazerData = findCategoryData(category);
        if (trailblazerData) {
            initialTrailblazerCat = trailblazerData.id;
        }
    } else if (cat) {
        const trailblazerData = findCategoryData(cat);
        if (trailblazerData) {
            initialTrailblazerCat = trailblazerData.id;
        }
    }

    return (
        <span id="top">
            {showRecaptcha &&
                <div className={`recaptcha-holder${showRecaptcha ? ' recaptcha-on' : ''}`}>
                    <div className='grow'></div>
                    <div className="recaptcha-panel">
                        <h1>Before we submit your vote...</h1>
                        <p>Please complete the following Captcha to prove you are not a robot.</p>
                        <p>&nbsp;</p>
                        <ReCAPTCHA
                            sitekey="6Lcbx7AeAAAAAG_Me-BDJNdzs7tWXw_PINyUqG8Q"
                            onChange={captchaOnChange}
                        />
                    </div>
                    <div className='grow'></div>
                </div>
            }

            {currentPhase === PHASES.LAUNCHING_SOON &&
                <ComingSoon></ComingSoon>
            }

        {currentPhase !== PHASES.LAUNCHING_SOON &&
        <>
            <Nav scrollToSection={scrollToSection}></Nav>
            <AboutHeader scrollToSection={scrollToSection} phase={currentPhase}></AboutHeader>
            <ROTYSection showRestaurantInfo={showRestaurantInfo} instagramFunction={goToInstagram} orderFunction={goToOrder} voteFunction={registerVote} phase={currentPhase} votes={currentVotes} initialRegion={initialRegion} cardTransitionInFunction={cardTransitionInFunction} cardTransitionOutFunction={cardTransitionOutFunction}></ROTYSection>
            <TrailblazersSection showTrailblazerInfo={showTrailblazerInfo} instagramFunction={goToInstagram} orderFunction={goToOrder} infoFunction={showTrailblazerInfo} phase={currentPhase} initialCategory={initialTrailblazerCat} cardTransitionInFunction={cardTransitionInFunction} cardTransitionOutFunction={cardTransitionOutFunction}></TrailblazersSection>
            <PreviousWinnersSection showPreviousWinners={showPreviousWinners}></PreviousWinnersSection>
            {/* <FaqSection></FaqSection> */}
            <Footer></Footer>
            {showingPreviousWinners &&
                <InfoPopout closedCallback={previousWinnersClosedCallback}>
                    <div className="info-pop-content-holder">
                        <div className="prev-winners-pop-content">
                            <div className="prev-winners-pop-scroll-area">
                                <h1 className="prev-winners">Restaurant of the Year 2023 winners</h1>
                                <p className="prev-winners">Congratulations to Sandwich Sandwich which has been named the Uber Eats Restaurant of the Year and awarded £100,000.</p>
                                <PrevWinnerCard
                                    winner={PREV_WINNERS.roty2023}
                                    icon={ROTY_WINNER_ICON}
                                    category='Restaurant of the Year 2023'
                                    instagramFunction={goToInstagram}
                                    orderFunction={goToOrder}
                                    shareFunction={share}
                                />
                                <h2 className="prev-winners">Pioneer and Innovators Awards 2023</h2>
                                <PrevWinnerCard
                                    winner={PREV_WINNERS.femaleChef2023}
                                    icon={FEMALE_CHEF_ICON}
                                    category='Female Chef of the Year Winner'
                                    instagramFunction={goToInstagram}
                                    orderFunction={goToOrder}
                                    shareFunction={share}
                                />
                                <PrevWinnerCard
                                    winner={PREV_WINNERS.sustainable2023}
                                    icon={SUSTAINABLE_RESTAURANT_ICON}
                                    category='Sustainable Restaurant of the Year Winner'
                                    instagramFunction={goToInstagram}
                                    orderFunction={goToOrder}
                                    shareFunction={share}
                                />
                                <PrevWinnerCard
                                    winner={PREV_WINNERS.community2023}
                                    icon={SOCIAL_IMPACT_ICON}
                                    category='Social Impact Restaurant of the Year Winner'
                                    instagramFunction={goToInstagram}
                                    orderFunction={goToOrder}
                                    shareFunction={share}
                                />
                                <PrevWinnerCard
                                    winner={PREV_WINNERS.health2023}
                                    icon={HEALTH_FOCUSED_ICON}
                                    category='Health Focused Restaurant of the Year Winner'
                                    instagramFunction={goToInstagram}
                                    orderFunction={goToOrder}
                                    shareFunction={share}
                                />
                            </div>
                        </div>
                    </div>
                </InfoPopout>
            }
            {showingRestaurantInfo &&
                <InfoPopout closedCallback={restaurantInfoClosedCallback}>
                    <div className="info-pop-content-holder">
                        {!Array.isArray(restaurantInfoData.image) &&
                            <div className="info-pop-image" style={{ backgroundImage: `url(./img/${restaurantInfoData.image})` }}></div>
                        }
                        {Array.isArray(restaurantInfoData.image) &&
                            <Carousel
                                swipeable={restaurantInfoData.image.length > 1}
                                draggable={restaurantInfoData.image.length > 1}
                                showDots={restaurantInfoData.image.length > 1}
                                customDot={<CustomDot />}
                                arrows={restaurantInfoData.image.length > 1}
                                responsive={{ all: { breakpoint: { min: 0, max: 1000000 }, items: 1 } }}
                                ssr={false} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={false /*restaurantInfoData.image.length > 1*/}
                                autoPlaySpeed={3000}
                                keyBoardControl={false}
                                transitionDuration={500}
                                containerClass=""
                                dotListClass=''
                            >
                                {
                                    restaurantInfoData.image.map((image, index) => {
                                        return (
                                            <div key={index} className="info-pop-image" style={{ backgroundImage: `url(./img/restaurants/${image})` }}></div>
                                        )
                                    })
                                }
                            </Carousel>
                        }
                        <div className="info-pop-content">
                            <h2 className="restaurant-card__info__name">{restaurantInfoData.name}</h2>
                            <div className="restaurant-card__info__location">
                                <img className='small-info-icon' src={LocationIcon} alt='Location icon' />
                                <p>{AREAS_BY_ID[restaurantInfoData.region].label}</p>
                            </div>
                            <div className="info-pop-scroll-area">
                                <p>{nl2br(restaurantInfoData.description)}</p>
                            </div>
                        </div>
                        <div className="info-pop-buttons">
                            <div className='restaurant-card__info__buttons'>
                                <button className="restaurant-card__info__buttons__info square light" onClick={() => share(restaurantInfoData, restaurantInfoData.id)}>{SHARE_ICON}</button>
                                {restaurantInfoData?.instagram !== '' &&
                                    <button className="side-card__info__buttons__instagram square light" onClick={() => goToInstagram(restaurantInfoData)}>{INSTA_ICON}</button>
                                }
                                <button className={`side-card__info__buttons__order grow${(currentPhase === PHASES.VOTE && currentVotes.indexOf(restaurantInfoData.id) === -1) ? ' light' : ' black'}`} onClick={() => goToOrder(restaurantInfoData)}>Order</button>
                            </div>
                            {currentPhase === PHASES.VOTE &&
                                <>
                                    {currentVotes.indexOf(restaurantInfoData.id) === -1 &&
                                        <button className="black" onClick={() => registerVote(restaurantInfoData.id, restaurantInfoData.region)}>Vote now</button>
                                    }
                                    {currentVotes.indexOf(restaurantInfoData.id) !== -1 &&
                                        <div className='voted-holder side-panel'>Thanks for voting</div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </InfoPopout>
            }
            {showingTrailblazerInfo &&
                <InfoPopout closedCallback={trailblazerInfoClosedCallback}>
                    <div className="info-pop-content-holder">
                        {!Array.isArray(trailblazerInfoData.image) &&
                            <div className="info-pop-image" style={{ backgroundImage: `url(./img/${trailblazerInfoData.image})` }}></div>
                        }
                        {Array.isArray(trailblazerInfoData.image) &&
                            <Carousel
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                customDot={<CustomDot />}
                                arrows={true}
                                responsive={{ all: { breakpoint: { min: 0, max: 1000000 }, items: 1 } }}
                                ssr={false} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                keyBoardControl={false}
                                transitionDuration={500}
                                containerClass=""
                                dotListClass=''
                            >
                                {
                                    trailblazerInfoData.image.map((image, index) => {
                                        return (
                                            <div key={index} className="info-pop-image" style={{ backgroundImage: `url(./img/${image})` }}></div>
                                        )
                                    })
                                }
                            </Carousel>
                        }
                        <div className="info-pop-content">
                            <h2 className='trailblazer-card__info__name'>{trailblazerInfoData.name}</h2>
                            <h3>{trailblazerInfoData.restaurant}</h3>
                            <div className='trailblazer-card__info__location'>
                                <img className='small-info-icon' src={LocationIcon} alt='Location icon' />
                                <p>{trailblazerInfoData.location}</p>
                            </div>
                            <div className="info-pop-scroll-area">
                                <p>{nl2br(trailblazerInfoData.description)}</p>
                            </div>
                        </div>
                        <div className="info-pop-buttons">
                            <div className='trailblazer-card__info__buttons'>
                                <button className='trailblazer-card__info__buttons__info square light' onClick={() => share(restaurantInfoData)}>{SHARE_ICON}</button>
                                <button className='side-card__info__buttons__instagram square light' onClick={() => goToInstagram(trailblazerInfoData)}>{INSTA_ICON}</button>
                                <button className='trailblazer-card__info__buttons__order black' onClick={() => goToOrder(trailblazerInfoData)}>Order</button>
                            </div>
                        </div>
                    </div>
                </InfoPopout>
            }
        </>
        }
        </span>
    );
}